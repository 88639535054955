export default function Carousel() {
    const workImgs = [
        
        'menu-img1.png',
        'menu-img2.png',
        'menu-img3.jpg',
        'menu-img4.jpg',
        'menu-img5.png',
        'menu-img6.jpg',
        'menu-img7.jpg',
        'menu-img8.png',
        'menu-img9.png',
    ]
    return (


        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src='/MENU/menu-img0.png' className="d-block w-100" alt="..." />
                </div>
                {workImgs.map((img, index) => {
                    return (
                        <div className="carousel-item" key={index}>
                            <img src={`/MENU/${img}`} className="d-block w-100" alt="..." />
                        </div>
                    )
                })}
                
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    )
}