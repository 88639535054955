import WaButton from '../wa-button/Wabutton'
import Card from './Card'
import CardZ from './CardZ'
import Portada from './Portada'
import './home.css'
export default function Home() {
	const services = [
		{
			id: 'tablaroca',
			title: 'Tablaroca',
			description: [
				'Materiales',
				'Instalación',
			],
			imageSrc: ['TABLAROCA1.png', 'TABLAROCA2.png']
		},
		{
			id: 'aluminieria',
			title: 'Aluminio',
			description: [
				'Ventanas',
				'Puertas',
				'Barandales',
				'Vidrio',
				'Estructura'

			],
			// imageSrc: '/work/Ventanas.jpg'
			imageSrc: ['ALUMINIO FIJOS.png', 'ALUMINIO VENTANAS CORREDIZAS.png', 'ALUMINIO-BANO.png', 'ALUMINIO-PUERTAS.png']
		},
		{
			id: 'herreria',
			title: 'Herrería',
			description: [
				'Protectores',
				'Rejas',
				'Estructura',
			],
			imageSrc: ['HERRERIA PROTECTORES.png', 'HERRERIA REJA Y BARANDAL.png']
		},
		{
			id: 'construccion',
			title: 'Construcción',
			description: [
				'Trámites', 'Permisos',
				'Diseño',
				'Remodelaciones',
				'Ingeniería Civil',
			],
			imageSrc: ['CONSTRUCCION1.png', 'CONSTRUCCION2.png', 'CONSTRUCCION3.png', 'CONSTRUCCION4.png']
		},
	]
	// const portada = '/PORTADA_IC_WEB.jpg'
	// const portada2 = '/PORTADA_ALUM_WEB.jpg'
	return (
		<div className='home'>
			<div className="cover-image" >
				<Portada />
			</div>

			<div id='nosotros' className='intro-section'>
				<p>
					Empresa especializada en la venta de material e instalación de tablaroca, cancelería de aluminio, servicios completos de hererría y construcción/remodelación de inmuebles.
				</p>
				<p>Comprometidos con la calidad y satisfacción del cliente.</p>
			</div>

			<div className='services-section'>
				<h1>Servicios</h1>
				<div className='card-section'>
					{services.map((service, index) =>
							<Card
								key={index}
								id={service.id}
								title={service.title}
								description={service.description}
								imageSrc={service.imageSrc}
							/>
					)}
				</div>
			</div>
			
			<WaButton />

		</div>
	)
}