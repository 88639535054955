import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
// import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import CardRouter from './components/card-router/CardRouter';
import Layout from './layout/Layout';

function App() {
  return (
    <BrowserRouter>

      <Routes>
        <Route path='/menu' Component={CardRouter}></Route>
        <Route path='/' element={
          <Layout>
            <Home />
          </Layout>
        }></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
