import './navbar.css'
import { NavLink, useLocation } from 'react-router-dom'

import { useEffect, useState } from 'react'

let SECTIONS = [
    // { id: 'menu', className: 'nav-item', to: '/menu', name: 'MENU' },
    { id: 'nosotros', className: 'nav-item', to: '#nosotros', name: 'NOSOTROS' },
    { id: 'contacto', className: 'nav-item', to: '#contacto', name: 'CONTACTO' },
]
let SERVICIOS = [
    { to: '#tablaroca', name: 'Tabla Roca' },
    { to: '#aluminieria', name: 'Aluminiería' },
    { to: '#herreria', name: 'Herrería' },
    { to: '#construccion', name: 'Construcción' },
    // { to: '/ingenieria', name: 'Ingeniería' },
]
export default function Navbar() {

    const location = useLocation();
    const [dropContent, setDropContent] = useState(false)

    useEffect(() => {
        let sections = document.querySelectorAll('.nav-item.active')
        sections.forEach((section) => {
            section?.setAttribute('class', "nav-item")
        })
        if (!dropContent) {
            let serviceLabel = document.querySelector('#servicios')
            serviceLabel?.setAttribute('class', "nav-item")
        }

        switch (location.pathname) {
            case '/#contacto':
                let sectionC = document.querySelector('#contacto')
                sectionC?.setAttribute('class', "nav-item active")
                break

            case '/#nosotros':
                let sectionN = document.querySelector('#nosotros')
                sectionN?.setAttribute('class', "nav-item active")
                break


            default:
                break
        }
    }, [dropContent, location.pathname])


    return (
        <nav className='nav-container'>
            <div className="brand">
                <NavLink to="/">
                    <img
                        src='./LOGO_INMODI.png'
                        className='brand-img'
                        alt='Logo de la empresa Grupo INMODI, para la barra de navegación' />
                </NavLink>
            </div>

            <div className="nav-items">
                {SECTIONS.map(({ id, className, to, name }) => (
                    <div key={id} className={className} >
                        <a href={to} >{name}</a>
                    </div>
                ))}
                <li id='servicios' className='nav-item' onClick={() => setDropContent(!dropContent)}>
                    <a href='#Servicios'>SERVICIOS...</a>
                    {dropContent &&
                        <div className={`dropbtn-content`}>
                            {SERVICIOS.map(({ to, name }) => (
                                <a href={to}>
                                    {name}
                                </a>
                            ))}
                        </div>
                    }
                </li>
                <li key={'catalogo'} id='catalogo' className='nav-item' onClick={() => window.open('/CATALOGO.pdf', '_blank')}>

                    <NavLink to="#" >
                        {'PRODUCTOS'}
                    </NavLink>
                </li>
            

            </div>
        </nav>

    )
}