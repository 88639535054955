import React from 'react';

interface CarouselItems {
    id: string;
    imgs: string[];
}

const DynamicCarousel: React.FC<CarouselItems> = ({id, imgs }) => {

    const [first, ...rest] = imgs;
    return (


        <div id={id} className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                
                {imgs.map((img, index) => {
                    console.log(img)
                    return (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                        <img src={`/SERVICIOS/${img}`} className="d-block w-100" alt="..." />
                    </div>
                    )
                })}


            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={`#${id}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#${id}`} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    )
}

export default DynamicCarousel;