import './footer.css';

export default function Footer() {

    return (
        <footer>
            <div id='contacto' className='footer'>

                <div className="footer__contact">
                    <p className='footer_title'>Contacto</p>
                    <a href="https://maps.app.goo.gl/P7cVB2ixfSBm8LSe6" target="_blank" rel="noopener noreferrer">
                        <p>C 65 #663 local 2 entre 14 y 16 Col. Emilio Portes Gil, Cp 97167</p>
                    </a>
                    <a href="tel:+529992285947" target="_blank"
                        rel="noreferrer" >
                        <p>9992285947</p>
                    </a>
                    <a href="mailto:cotizaciones@inmodi.com.mx" target="_blank"
                        rel="noreferrer" >
                        <p>cotizaciones@inmodi.com.mx</p>
                    </a>
                </div>
                {/* <div className="footer__links">
                    <h4>Enlaces Rápidos</h4>
                    <ul>
                        <li><a href="/contacto">Contacto</a></li>
                        <li><a href="/nosotros">Nosotros</a></li>
                        <li><a href="/aluminieria">Aluminiería</a></li>
                        <li><a href="/construccion">Construcción</a></li>
                        <li><a href="/ingenieria">Ingeniería</a></li>
                    </ul>
                </div> */}
                <div className="footer__social">
                    <p className='footer_title'>Redes Sociales</p>
                    <ul>
                        <li>
                            <a className='icon-link' href="http://www.facebook.com/INMODI">
                                <img className='icon' src="/facebook.png" alt="Facebook" />
                            </a>
                        </li>
                        <li>
                            <a className='icon-link' href="http://www.insatgram.com/_inmodi">
                                <img className='icon' src="/instagram.png" alt="Instagram" />
                            </a>
                        </li>

                    </ul>
                </div>
                <div className='footer_end'>
                    <p>INMODI 2024 &#174;</p>
                </div>
            </div>
        </footer>
    )
}