import React from 'react';
import './Card.css';
import DynamicCarousel from './DynamicCarousel';

interface CardProps {
    id: string;
    title: string;
    description: string[];
    imageSrc: string[];
}

const Card: React.FC<CardProps> = ({ id,title, description, imageSrc }) => {
    return (
        <div id={id} className="custom-card">
            <div className="content">
                <h2>{title}</h2>
                <ul className='description-list'>
                    {description.map((desc, index) => (
                        <li key={index}>{desc}</li>
                    ))}
                </ul>
            </div>
            <div className="image-container">
                <DynamicCarousel 
                    imgs={imageSrc}
                    id={`carouselExampleAutoplaying-${id}`}
                />
                
            </div>
        </div>
    );
};

export default Card;
