import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";

interface LayoutProps {
    children: React.ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <header>
                <Navbar />
            </header>
            <main>
                {children}
            </main>
            <Footer />
        </>
    );
};
export default Layout;