import { NavLink, /* useNavigate */ } from "react-router-dom"

const Portada = () => {
    // const navigate = useNavigate()
    return (
        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src='/PORTADA/ALUMINIO_PORTADA.jpg' className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src='/PORTADA/CONSTRUCCION_PORTADA.jpg' className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src='/PORTADA/HERRERIA_PORTADA.jpg' className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src='/PORTADA/TABLAROCA_PORTADA.jpg' className="d-block w-100" alt="..." />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
export default Portada;