import React from 'react';
import './wabutton.css';

const WaButton = () => {
  return (
    <div className="whatsapp-button-container">
      <a href="https://wa.me/529992285947" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <img src="/ICOS/ico-wpw.png" alt="WhatsApp" className="whatsapp-icon" />
        <span className="whatsapp-text">Cotiza tu proyecto</span>
      </a>
    </div>
  );
};

export default WaButton;
