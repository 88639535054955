import Carousel from './Carousel'
import './cardRouter.css'
export default function CardRouter() {
	// const portada = '/PORTADA_IC_WEB.jpg'
	// const portada2 = '/PORTADA_ALUM_WEB.jpg'
	return (
		<div className='menu' title='MENU'>
			<div className="carousel-menusection" >
				<Carousel />
			</div>

			<div className="options"

			>
				{/* Contenido de las opciones aquí */}
				<div className="links">
					<a href="../"
						rel="noreferrer">
						<div>
							<img src="/ICOS/ico-web.png" alt="Sitio web" />
							<span>Sitio web</span>
						</div>
					</a>
				</div>
				<div className="links">
					<a href="https://www.facebook.com/INMODI" target="_blank"
						rel="noreferrer">
						<div>
							<img src="/ICOS/ico-fb.png" alt="Facebook" />
							<span>Facebook</span>
						</div>
					</a>
				</div>
				<div className="links">
					<a href="https://wa.me/529992285947" target="_blank"
						rel="noreferrer" >
							<div>
								<img src="/ICOS/ico-wp.png" alt="Whatsapp" />
								<span>WhatsApp</span>
							</div>
					</a>
				</div>
				<div className="links">
					<a href="tel:+529992285947" target="_blank"
						rel="noreferrer" >
							<div>
								<img src="/ICOS/ico-tel.png" alt="Telefono" />
								<span>Teléfono</span>
							</div>
					</a>
				</div>

				<div className="links">
					<a href="mailto:cotizaciones@inmodi.com.mx" target="_blank"
						rel="noreferrer" >
						<div>
							<img src="/ICOS/ico-mail.png" alt="Email" />
							<span>Correo</span>
						</div>
						
					</a>
				</div>

				<div className="links">
					<a href="https://maps.app.goo.gl/P7cVB2ixfSBm8LSe6" target="_blank" rel="noopener noreferrer">
						<div>
							<img src="/ICOS/ico-ubi.png" alt="Ubicación" />
							<span>Ubicación</span>
						</div>
					</a>
				</div>
				{/* <li key={'catalogo'} id='catalogo' className='nav-item' onClick={() => window.open('/CATALOGO.pdf', '_blank')}>

                    <NavLink to="#" >
                        {'PRODUCTOS'}
                    </NavLink>
                </li> */}
				<div className="links-last" onClick={() => window.open('/SERVICIOS.pdf', '_blank')}>
					<a href="#"
						rel="noreferrer">
						<div>
							<img src="/ICOS/ico-servicios.png" alt="Sitio web" />
							<span>Precios base</span>
						</div>
					</a>
				</div>

			</div>
		</div>
	)
}